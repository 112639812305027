import React, { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import Cookies from 'js-cookie';
import api from '../utils/api';

// 在文件开头设置 axios 的基础 URL
axios.defaults.baseURL = 'https://www.oiiet.com';  // Django 服务器地址

function RegisterModal({ isOpen, onClose, setUser, setIsLoginOpen }) {
  const [step, setStep] = useState(1);
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    // 检查 Cookies 中是否有用户信息
    const userCookie = Cookies.get('user');
    if (userCookie) {
      setUser(JSON.parse(userCookie));
    }
  }, [setUser]);

  // 添加 useEffect 处理模态框打开/关闭时的滚动条
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  const startCountdown = () => {
    setCountdown(30);
    const timer = setInterval(() => {
      setCountdown((prevCount) => {
        if (prevCount <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prevCount - 1;
      });
    }, 1000);
  };

  const handleConfirm = async () => {
    // 添加邮箱验证
    if (!email || !email.includes('@')) {
      alert('请输入有效的邮箱地址');
      return;
    }

    if (userName.length < 3) {
      alert('您的名字长度必须不少于3个字符');
      return;
    }

    console.log('开始发送验证码请求', { email, userName }); // 添加调试日志

    try {
      const response = await api.post('/api/auth/send_verification/', {
        email,
        user_name: userName,
      });
      console.log('验证码发送响应:', response.data); // 添加调试日志
      setStep(2);
      startCountdown();
    } catch (error) {
      console.error('发送验证码失败:', error);
      if (error.response && error.response.data) {
        const { error: errorType, message } = error.response.data;
        
        switch (errorType) {
          case 'user_exists':
            alert('您的名字已被注册，请使用其他名字');
            break;
          case 'email_exists':
            alert('您的邮箱已被注册，请使用其他邮箱');
            break;
          case 'send_failed':
            alert('发送验证码失败，请重试');
            break;
          default:
            alert(message || '发送验证码失败，请重试');
        }
      } else {
        alert('发送验证码失败，请重试');
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // 添加密码长度验证
    if (password.length < 8) {
      alert('请输入密码最少8位');
      return;
    }
    
    if (password !== confirmPassword) {
      alert('两次输入的密码不一致');
      return;
    }

    try {
      const response = await axios.post('/api/auth/register/', {
        user_name: userName,
        user_email: email,
        user_password: password,
        verification_code: verificationCode
      });

      if (response.data.user) {
        const userData = {
          user_id: response.data.user.user_id,
          user_name: response.data.user.user_name,
          user_email: response.data.user.user_email,
          isAuthenticated: response.data.isAuthenticated,
          user_avatar: response.data.user.user_avatar || 'https://www.oiiet.com/static/avatar/default-avatar.png'
        };
        setUser(userData);
        Cookies.set('user', JSON.stringify(userData), { expires: 7 });
        
        // 触发头像更新事件
        const avatarUpdateEvent = new CustomEvent('avatarUpdate', {
          detail: { 
            avatar: userData.user_avatar,
            timestamp: new Date().getTime()
          }
        });
        window.dispatchEvent(avatarUpdateEvent);
        
        alert('注册成功！');
        onClose();
      }
    } catch (error) {
      console.error('注册失败:', error);
      if (error.response && error.response.data) {
        const { error: errorType, message } = error.response.data;
        
        switch (errorType) {
          case 'user_exists':
            alert('您的名字已被注册，请使用其他名字');
            break;
          case 'email_exists':
            alert('您的邮箱已被注册，请使用其他邮箱');
            break;
          case 'invalid_code':
            alert('验证码无效或已过期，请重新获取');
            break;
          default:
            alert(message || '注册失败，请重试');
        }
      } else {
        alert('注册失败，请重试');
      }
    }
  };

  const handleLoginClick = () => {
    onClose(); // 关闭注册模态框
    setIsLoginOpen(true); // 打开登录模态框
  };

  const renderVerificationButton = () => {
    if (countdown > 0) {
      return (
        <span className="text-gray-500 text-sm">
          验证码已发送 ({countdown}s)
        </span>
      );
    }
    return (
      <button
        type="button"
        className="text-[#000000] text-sm hover:opacity-75"
        onClick={handleConfirm}
      >
        重新获取验证码
      </button>
    );
  };

  // 修改密码长度控制函数，移除最大长度限制
  const handlePasswordChange = (e, setPasswordFunc) => {
    const value = e.target.value;
    setPasswordFunc(value);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog 
        as="div" 
        className="relative z-10" 
        onClose={() => {}}  // 修改这里，使点击背景时不关闭
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-[420px] transform overflow-hidden rounded-2xl bg-white p-10 text-left align-middle shadow-xl transition-all min-h-[560px] relative">
                <div className="flex justify-between items-center mb-12">
                  <button
                    onClick={() => setStep(1)}
                    className="text-black w-8 h-[20px] leading-[20px] text-base"
                  >
                    {step === 2 ? '返回' : ''}
                  </button>
                  <Dialog.Title
                    as="h3"
                    className="text-3xl font-medium text-center flex-grow"
                  >
                    注册
                  </Dialog.Title>
                  <button
                    onClick={onClose}  // 保留关闭按钮的功
                    className="text-black w-8 h-[20px] leading-[20px] text-base"
                  >
                    关闭
                  </button>
                </div>

                {step === 1 ? (
                  <div className="flex flex-col min-h-[320px] mt-12">
                    <div className="flex items-center space-x-4">
                      <label className="w-24 text-base text-black">您的名字</label>
                      <div className="flex-grow">
                        <input
                          type="text"
                          value={userName}
                          onChange={(e) => setUserName(e.target.value)}
                          className="w-full rounded-full border border-black px-8 py-3 text-center"
                          required
                          minLength={3}
                        />
                      </div>
                    </div>
                    
                    <div className="flex items-center space-x-4 mt-12">
                      <label className="w-24 text-base text-black">您的邮箱</label>
                      <div className="flex-grow">
                        <input
                          type="email"
                          placeholder=""
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="w-full rounded-full border border-black px-8 py-3 text-center"
                          required
                        />
                      </div>
                    </div>

                    <div className="flex justify-end mt-24">
                      <button
                        type="button"
                        onClick={handleConfirm}
                        className="w-32 bg-[#ff0000] text-white rounded-full py-3 text-xl"
                      >
                        确认
                      </button>
                    </div>

                    <div className="absolute bottom-8 left-0 right-0 text-center">
                      <button
                        onClick={handleLoginClick}
                        className="text-black hover:text-black"
                      >
                        已经注册？立即登录。
                      </button>
                    </div>
                  </div>
                ) : (
                  <form onSubmit={handleSubmit} className="flex flex-col min-h-[320px] mt-8">
                    <div className="flex items-center space-x-4">
                      <label className="w-24 text-base text-black">验证码</label>
                      <div className="flex-grow">
                        <div className="relative">
                          <input
                            type="text"
                            value={verificationCode}
                            onChange={(e) => setVerificationCode(e.target.value)}
                            className="w-full rounded-full border border-black px-8 py-3 text-center"
                            required
                          />
                          <div className="absolute right-0 mt-2">
                            {renderVerificationButton()}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex items-center space-x-4 mt-12">
                      <label className="w-24 text-base text-black">您的密码</label>
                      <div className="flex-grow">
                        <input
                          type="password"
                          value={password}
                          onChange={(e) => handlePasswordChange(e, setPassword)}
                          className="w-full rounded-full border border-black px-8 py-3 text-center"
                          required
                          minLength={8}
                        />
                      </div>
                    </div>

                    <div className="flex items-center space-x-4 mt-8">
                      <label className="w-24 text-base text-black">确认密码</label>
                      <div className="flex-grow">
                        <input
                          type="password"
                          value={confirmPassword}
                          onChange={(e) => handlePasswordChange(e, setConfirmPassword)}
                          className="w-full rounded-full border border-black px-8 py-3 text-center"
                          required
                          minLength={8}
                        />
                      </div>
                    </div>

                    <div className="flex justify-end mt-16">
                      <button
                        type="submit"
                        className="w-32 bg-[#ff0000] text-white rounded-full py-3 hover:bg-[#cc0000] text-xl"
                      >
                        注册
                      </button>
                    </div>

                    <div className="absolute bottom-8 left-0 right-0 text-center">
                      <button
                        onClick={handleLoginClick}
                        className="text-black hover:text-black"
                      >
                        已经注册？立即登录。
                      </button>
                    </div>
                  </form>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default RegisterModal;