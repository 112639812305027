import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>About</title>
        <meta name="description" content="关于我们" />
      </Helmet>

      <div className="max-w-screen-xl mx-auto px-6 py-12">
        <h1 className="text-3xl font-bold mb-8">About</h1>
        
        <div className="space-y-8 max-w-2xl">
          {/* 中文内容 */}
          <div className="text-base leading-relaxed space-y-4 text-[#000000]">
            <p>
              Oiiet是一个旨在为身边志同道合，深藏不露的朋友提供围绕生活研究、艺术创作而展开分享和体验的社区。
            </p>
            <p>
              我们致力于提升彼此信心，相信分享是真诚的准备，而非绞尽脑汁的表达。体验，轻装上阵。
            </p>
            <p>
              我们将从您的分享或体验中，收取一小撮佣金，为我们加油、打气。您当前看到的是艺术家ChaoChaos提供的物美价廉的艺术作品。
            </p>
          </div>

          {/* 英文内容 */}
          <div className="text-base leading-relaxed space-y-4 text-[#000000]">
            <p>
              Oiiet is a community designed to offer like-minded, unassuming individuals a platform for sharing and experiencing explorations of life and artistic creation.
            </p>
            <p>
              We are dedicated to boosting mutual confidence, believing that sharing is a sincere act of preparedness rather than an expression born out of overthinking. Embrace experiences with ease.
            </p>
            <p>
              We will collect a small commission from your shared experiences to help fuel and support our efforts. What you are currently seeing is a high-quality, affordable piece of art provided by the artist ChaoChaos.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;