import axios from 'axios';

const api = axios.create({
    baseURL: 'https://www.oiiet.com',  // 确保这个地址是您的Django服务器地址
    headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    },
    withCredentials: true
});

// 添加请求拦截器
api.interceptors.request.use(function (config) {
    // 确保验证码请求使用 POST 方法
    if (config.url.includes('/auth/send_verification')) {
        config.method = 'post';
    }
    
    // 从cookie中获取CSRF token
    const csrfToken = document.cookie.split('; ')
        .find(row => row.startsWith('csrftoken='))
        ?.split('=')[1];
    
    if (csrfToken) {
        config.headers['X-CSRFToken'] = csrfToken;
    }
    
    return config;
}, function (error) {
    return Promise.reject(error);
});

// 添加响应拦截器用于调试
api.interceptors.response.use(
    response => response,
    error => {
        console.error('API Error:', {
            status: error.response?.status,
            data: error.response?.data,
            headers: error.response?.headers,
            config: error.config
        });
        return Promise.reject(error);
    }
);

export default api; 