import React, { useState, useEffect, useLayoutEffect, Fragment } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import LoginModal from '../components/LoginModal';
import RegisterModal from '../components/RegisterModal';
import { Dialog, Transition } from '@headlessui/react';
import { Helmet } from 'react-helmet-async';

// 设置 axios 默认配置
axios.defaults.baseURL = 'https://www.oiiet.com';
axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

function ProductDetails() {
  const { productId } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [showContactForm, setShowContactForm] = useState(false);
  const [contactInfo, setContactInfo] = useState({
    phone: '',
    address: ''
  });
  const [tempInput, setTempInput] = useState({
    phone: '',
    address: ''
  });
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isRegisterOpen, setIsRegisterOpen] = useState(false);
  const [user, setUser] = useState(() => {
    const userCookie = Cookies.get('user');
    return userCookie ? JSON.parse(userCookie) : null;
  });

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    
    const handleBeforeUnload = () => {
      window.scrollTo(0, 0);
    };
    
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`/api/products/${productId}/`);
        console.log('Product details:', response.data);
        setProduct(response.data);
      } catch (error) {
        console.error('获取产品详情失败:', error);
        setProduct(null);  // 所有错误统一处理：设置为 null 显示自定义错误页面
      } finally {
        setLoading(false);
      }
    };
    
    fetchProduct();
  }, [productId]);

  useEffect(() => {
    if (isLightboxOpen) {
      // 禁用滚动
      document.body.style.overflow = 'hidden';
      // 防止iOS设备的弹性滚动
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
      document.body.style.top = `-${window.scrollY}px`;
    } else {
      // 恢复滚动
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.width = '';
      document.body.style.top = '';
      document.body.style.overflow = 'unset';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }

    // 清理函数
    return () => {
      document.body.style.overflow = 'unset';
      document.body.style.position = '';
      document.body.style.width = '';
      document.body.style.top = '';
    };
  }, [isLightboxOpen]);

  const handleCloseLightbox = (e) => {
    if (e.target === e.currentTarget) {
      setIsLightboxOpen(false);
    }
  };

  const handleImageChange = (direction) => {
    if (!product || !product.images) return;
    
    if (direction === 'next') {
      setCurrentImageIndex((prev) => 
        prev === product.images.length - 1 ? 0 : prev + 1
      );
    } else {
      setCurrentImageIndex((prev) => 
        prev === 0 ? product.images.length - 1 : prev - 1
      );
    }
  };

  const handlePurchase = async () => {
    if (!user) {
      console.log('用户未登录，显示登录模态框');
      setIsLoginOpen(true);
      return;
    }

    try {
      const profileResponse = await axios.get('/api/profiles/1/', {
        withCredentials: true
      });
      
      const userProfile = profileResponse.data;
      
      const missingPhone = !userProfile?.user_phone_number;
      const missingAddress = !userProfile?.user_address;
      
      if (missingPhone || missingAddress) {
        console.log('缺少联系信息，显示表单');
        const initialInfo = {
          phone: userProfile?.user_phone_number || '',
          address: userProfile?.user_address || ''
        };
        setContactInfo(initialInfo);
        setTempInput(initialInfo);
        setShowContactForm(true);
        return;
      }

      console.log('跳转到支付页面');
      navigate(`/payment/${product.product_id}`);
      
    } catch (error) {
      console.error('获取用户资料失败:', error);
      if (error.response?.status === 401) {
        setIsLoginOpen(true);
        return;
      }
      alert('发生错误，请重试');
    }
  };

  const handleContactSubmit = async (e) => {
    e.preventDefault();
    try {
      setContactInfo(tempInput);

      await axios.patch('/api/profiles/1/', {
        user_phone_number: tempInput.phone,
        user_address: tempInput.address
      }, {
        headers: {
          'X-CSRFToken': Cookies.get('csrftoken'),
        },
        withCredentials: true
      });

      setShowContactForm(false);
      navigate(`/payment/${product.product_id}`);
      
    } catch (error) {
      console.error('保存联系信息失败:', error);
      alert('保存失败，请重试');
    }
  };

  // 添加 useEffect 来控制模态框打开时的滚动条
  useEffect(() => {
    if (showContactForm) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    // 清理函数
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [showContactForm]);

  if (loading) {
    return (
      <>
        <Helmet>
          <title>加载中...</title>
        </Helmet>
        <div className="fixed inset-0 flex items-center justify-center">
          <div className="text-2xl hover:opacity-75">加载中...</div>
        </div>
      </>
    );
  }

  if (!product) {
    return (
      <>
        <Helmet>
          <title>产品未找到</title>
          <meta name="description" content="抱歉该产品可能不存在或已被删除" />
        </Helmet>
        <div className="max-w-screen-xl mx-auto px-6 py-12">
          <div className="h-[360px] flex items-center justify-center">
            <div className="text-center">
              <h2 className="text-[30px] font-bold text-[#000000]">产品加载失败</h2>
              <p className="mt-2 text-[16px] text-[#000000]">该产品可能不存在或已被删除</p>
              <button 
                onClick={() => navigate('/')} 
                className="mt-4 px-4 py-2 bg-[#ff0000] text-[#ffffff] rounded-full hover:bg-[#ff0000] text-[20px]"
              >
                返回首页
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{`${product.product_name}`}</title>
        <meta name="description" content={product.product_introduce} />
        <meta property="og:title" content={`${product.product_name}`} />
        <meta property="og:description" content={product.product_introduce} />
        {product.images && product.images.length > 0 && (
          <meta property="og:image" content={product.images[0].image} />
        )}
      </Helmet>

      <div className="max-w-screen-xl mx-auto px-6 py-12">
        <div className="text-3xl font-bold mb-8">
          <span 
            className="cursor-pointer text-3xl"
            onClick={() => navigate('/')}
          >
            返回
          </span>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          {/* 左侧：图片展示区 */}
          <div>
            {/* 主图片 */}
            <div 
              className="relative aspect-[4/3] rounded-2xl overflow-hidden cursor-pointer"
              onClick={() => setIsLightboxOpen(true)}
            >
              {product.images && product.images.length > 0 ? (
                <img
                  src={product.images[currentImageIndex].image}
                  alt={product.product_name}
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="absolute w-full h-full bg-gray-200 flex items-center justify-center">
                  <span className="text-gray-400">暂无图片</span>
                </div>
              )}
            </div>

            {/* 缩略图列表 */}
            <div className="mt-4">
              {product.images && product.images.length > 0 && (
                <div className="grid grid-cols-5 gap-2">
                  {product.images.map((image, index) => (
                    <div
                      key={index}
                      className={`aspect-square rounded-lg overflow-hidden cursor-pointer ${
                        currentImageIndex === index ? 'ring-2 ring-blue-500' : ''
                      }`}
                      onClick={() => setCurrentImageIndex(index)}
                    >
                      <img
                        src={image.image}
                        alt={`${product.product_name} ${index + 1}`}
                        className="w-full h-full object-cover"
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* 右侧：产品信息 */}
          <div className="relative min-h-[400px] lg:aspect-[4/3]">
            <div className="h-full flex flex-col">
              <div className="flex-1">
                <h1 className="text-3xl font-bold text-[#000000] mb-4">
                  {product.product_name}
                </h1>
                
                {/* 第一条分割线 */}
                <div className="w-full h-[1px] bg-gray-200 mb-6"></div>
                
                <p className="text-xl text-[#000000] mb-6">
                  {product.product_introduce}
                </p>
                
                {/* 第二条分割线 */}
                <div className="w-full h-[1px] bg-gray-200 mb-6"></div>
                
                <div className="text-xl font-bold text-[#000000]">
                  ¥{product.product_price}
                </div>
              </div>

              {/* 购买按钮 */}
              <div className="mt-8 flex justify-end">
                <button
                  onClick={handlePurchase}
                  className="w-32 bg-[#ff0000] text-white rounded-full py-3 text-xl"
                >
                  购买
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* 灯箱 */}
        {isLightboxOpen && (
          <div 
            className="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center"
            onClick={handleCloseLightbox}
          >
            {/* 关闭按钮 - 固定在右上角 */}
            <button
              className="fixed top-4 right-4 lg:top-8 lg:right-8 text-white text-4xl lg:text-6xl z-50 w-12 h-12 flex items-center justify-center"
              onClick={() => setIsLightboxOpen(false)}
            >
              ×
            </button>

            {/* 图片和箭头的容器 */}
            <div className="relative w-full h-[calc(100vh-120px)] lg:h-auto lg:max-w-5xl mx-auto px-4 lg:px-0">
              <div className="relative flex items-center justify-center h-full">
                {/* 桌面端的左右箭头和图片 */}
                {product.images && product.images.length > 1 && (
                  <button
                    className="absolute -left-32 text-white text-8xl w-16 h-32 hidden lg:flex items-center justify-center"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleImageChange('prev');
                    }}
                  >
                    ‹
                  </button>
                )}

                <div className="h-full lg:h-auto lg:max-h-[70vh] w-full flex flex-col items-center">
                  <img
                    src={product.images[currentImageIndex].image}
                    alt={product.product_name}
                    className="w-full h-full lg:h-auto lg:max-h-[70vh] object-contain mx-auto"
                    onClick={(e) => e.stopPropagation()}
                  />

                  {/* 移动端的左右箭头 */}
                  {product.images && product.images.length > 1 && (
                    <div className="fixed bottom-4 left-0 right-0 flex justify-center items-center space-x-8 mt-4 lg:hidden">
                      <button
                        className="text-white text-4xl w-12 h-12 flex items-center justify-center"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleImageChange('prev');
                        }}
                      >
                        ‹
                      </button>
                      <button
                        className="text-white text-4xl w-12 h-12 flex items-center justify-center"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleImageChange('next');
                        }}
                      >
                        ›
                      </button>
                    </div>
                  )}
                </div>

                {product.images && product.images.length > 1 && (
                  <button
                    className="absolute -right-32 text-white text-8xl w-16 h-32 hidden lg:flex items-center justify-center"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleImageChange('next');
                    }}
                  >
                    ›
                  </button>
                )}
              </div>
            </div>
          </div>
        )}

        {/* 联系信息表单模态框 */}
        <Transition appear show={showContactForm} as={Fragment}>
          <Dialog 
            as="div" 
            className="relative z-10" 
            onClose={() => {}}  // 禁用背景点击关闭
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-50" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-[420px] transform overflow-hidden rounded-2xl bg-white p-10 text-left align-middle shadow-xl transition-all min-h-[360px] relative">
                    {/* 标题部分 */}
                    <div className="flex justify-between items-center mb-12">
                      <div className="w-8"></div>
                      <Dialog.Title
                        as="h2"
                        className="text-[30px] font-medium text-center flex-grow"
                      >
                        联系信息
                      </Dialog.Title>
                      <button
                        onClick={() => setShowContactForm(false)}
                        className="text-black w-8"
                      >
                        关闭
                      </button>
                    </div>

                    <form onSubmit={handleContactSubmit} className="flex flex-col min-h-[180px] mt-12">
                      {/* 电话部分 */}
                      {contactInfo.phone ? (
                        <div className="flex items-center space-x-2">
                          <label className="min-w-[5.5rem] text-base text-[#000000]">您的电话：</label>
                          <div className="flex-grow">
                            <p className="text-base text-[#000000] px-8 py-3 text-left">{contactInfo.phone}</p>
                          </div>
                        </div>
                      ) : (
                        <div className="flex items-center space-x-2">
                          <label className="min-w-[5.5rem] text-base text-[#000000]">您的电话：</label>
                          <div className="flex-grow">
                            <input
                              type="tel"
                              pattern="[0-9]*"
                              maxLength={11}
                              value={tempInput.phone}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*$/.test(value) && value.length <= 11) {
                                  setTempInput(prev => ({...prev, phone: value}));
                                }
                              }}
                              className="w-full rounded-full border-2 border-black px-8 py-3 text-left text-base"
                              required
                            />
                          </div>
                        </div>
                      )}

                      {/* 地址部分 */}
                      {contactInfo.address ? (
                        <div className="flex items-center space-x-2 mt-12">
                          <label className="min-w-[5.5rem] text-base text-[#000000]">您的地址：</label>
                          <div className="flex-grow">
                            <p className="text-base text-[#000000] px-8 py-3 text-left">{contactInfo.address}</p>
                          </div>
                        </div>
                      ) : (
                        <div className="flex items-center space-x-2 mt-12">
                          <label className="min-w-[5.5rem] text-base text-[#000000]">您的地址：</label>
                          <div className="flex-grow">
                            <input
                              type="text"
                              value={tempInput.address}
                              onChange={(e) => setTempInput(prev => ({...prev, address: e.target.value}))}
                              className="w-full rounded-full border-2 border-black px-8 py-3 text-left text-base"
                              required
                            />
                          </div>
                        </div>
                      )}

                      {/* 按钮部分 */}
                      <div className="flex justify-end mt-24">
                        <button
                          type="submit"
                          className="w-32 bg-[#ff0000] text-white rounded-full py-3 text-[20px]"
                          onClick={(e) => {
                            e.preventDefault();
                            handleContactSubmit(e);
                          }}
                        >
                          确认
                        </button>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>

        {/* 添加登录和注册模态框 */}
        <LoginModal 
          isOpen={isLoginOpen} 
          onClose={() => setIsLoginOpen(false)}
          setUser={setUser}
          setIsRegisterOpen={setIsRegisterOpen}
        />
        <RegisterModal 
          isOpen={isRegisterOpen} 
          onClose={() => setIsRegisterOpen(false)}
          setUser={setUser}
          setIsLoginOpen={setIsLoginOpen}
        />
      </div>
    </>
  );
}

export default ProductDetails; 