import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import Cookies from 'js-cookie';

axios.defaults.baseURL = 'https://www.oiiet.com';
axios.defaults.withCredentials = true;

function PaymentCancel() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [productDetails, setProductDetails] = useState(null);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productId = searchParams.get('product_id');
        if (!productId) {
          setProductDetails(null);
          return;
        }

        // 获取商品信息
        const productResponse = await axios.get(`/api/products/${productId}/`, {
          withCredentials: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
          }
        });
        
        // 获取用户资料
        const profileResponse = await axios.get('/api/profiles/1/', {
          withCredentials: true
        });

        setProductDetails(productResponse.data);
        setProfile(profileResponse.data);
      } catch (error) {
        console.error('获取数据失败:', error);
        setProductDetails(null);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [searchParams]);

  // 加载状态
  if (loading) {
    return (
      <>
        <Helmet>
          <title>加载中...</title>
          <meta name="description" content="正在加载订单信息，请稍候..." />
        </Helmet>
        <div className="max-w-screen-xl mx-auto px-6 py-12">
          <div className="animate-pulse">
            <div className="h-8 bg-gray-200 rounded w-1/4 mb-8"></div>
            <div className="bg-gray-200 aspect-[16/9] rounded-2xl mb-8"></div>
            <div className="h-6 bg-gray-200 rounded w-3/4 mb-4"></div>
            <div className="h-4 bg-gray-200 rounded w-1/2 mb-8"></div>
            <div className="space-y-4">
              <div className="h-4 bg-gray-200 rounded w-full"></div>
              <div className="h-4 bg-gray-200 rounded w-5/6"></div>
            </div>
          </div>
        </div>
      </>
    );
  }

  // 错误状态
  if (!productDetails) {
    return (
      <>
        <Helmet>
          <title>订单加载失败</title>
          <meta name="description" content="抱歉订单信息加载失败，该产品可能不存在或已被删除" />
        </Helmet>
        <div className="max-w-screen-xl mx-auto px-6 py-12">
          <div className="h-[360px] flex items-center justify-center">
            <div className="text-center">
              <h2 className="text-[30px] font-bold text-[#000000]">产品加载失败</h2>
              <p className="mt-2 text-[16px] text-[#000000]">该产品可能不存在或已被删除</p>
              <button 
                onClick={() => navigate('/')} 
                className="mt-4 px-4 py-2 bg-[#ff0000] text-[#ffffff] rounded-full hover:bg-[#ff0000] text-[20px]"
              >
                返回首页
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{`${productDetails.product_name}`}</title>
        <meta name="description" content={`您已取消支付${productDetails.product_name}`} />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <div className="max-w-screen-xl mx-auto px-6 py-12">
        {/* 标题部分 */}
        <div className="text-3xl font-bold mb-8">
          <span 
            className="cursor-pointer text-3xl"
            onClick={() => navigate('/')}
          >
            返回
          </span>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          {/* 左侧：商品图片展示 */}
          <div>
            <div className="relative aspect-[4/3] rounded-2xl overflow-hidden">
              {productDetails?.images && productDetails.images.length > 0 ? (
                <img
                  src={productDetails.images[0].image}
                  alt={productDetails.product_name}
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="w-full h-full bg-gray-200" />
              )}
            </div>
          </div>

          {/* 右侧：商品信息 */}
          <div className="relative min-h-[400px] lg:aspect-[4/3]">
            <div className="h-full flex flex-col">
              <div className="flex-1">
                <h1 className="text-3xl font-bold text-[#000000] mb-4">
                  {productDetails?.product_name}
                </h1>
                
                <div className="w-full h-[1px] bg-gray-200 mb-6"></div>

                <div className="mb-6">
                  <p className="text-xl text-[#000000]">¥{productDetails?.product_price}</p>
                </div>
                
                <div className="w-full h-[1px] bg-gray-200 mb-6"></div>
                
                <div className="space-y-6">
                  <div>
                    <div className="mb-6">
                      <label className="block text-xl text-[#000000] mb-4">您的电话：</label>
                      <p className="text-xl text-[#000000]">{profile?.user_phone_number || '未设置'}</p>
                    </div>

                    <div className="w-full h-[1px] bg-gray-200 mb-6"></div>
                    
                    <div>
                      <label className="block text-xl text-[#000000] mb-4">您的地址：</label>
                      <p className="text-xl text-[#000000]">{profile?.user_address || '未设置'}</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* 重新查看按钮 - 调整位置和样式 */}
              <div className="mt-8 flex justify-end">
                <button
                  onClick={() => navigate(`/product/${searchParams.get('product_id')}`)}
                  className="w-40 bg-[#0000ff] text-white rounded-full py-3 transition-colors text-xl"
                >
                  重新查看
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentCancel;