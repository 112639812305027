import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

function NotFound() {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>页面不存在</title>
        <meta name="description" content="您访问的页面不存在" />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <div className="max-w-screen-xl mx-auto px-6 py-12">
        <div className="h-[360px] flex items-center justify-center">
          <div className="text-center">
            <h2 className="text-[30px] font-bold text-[#000000]">页面不存在</h2>
            <p className="mt-2 text-[16px] text-[#000000]">您访问的页面可能已被删除或移动</p>
            <button 
              onClick={() => navigate('/')} 
              className="mt-4 px-4 py-2 bg-[#ff0000] text-[#ffffff] rounded-full hover:bg-[#ff0000] text-[20px]"
            >
              返回首页
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default NotFound;