import React, { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import Cookies from 'js-cookie';

function LoginModal({ isOpen, onClose, setUser, setIsRegisterOpen }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/auth/login/', {
        email,
        password,
      });
      
      if (response.data.user) {
        const userData = response.data.user;
        
        // 先更新用户状态
        setUser(userData);
        
        // 然后设置 cookie
        Cookies.set('user', JSON.stringify(userData));
        
        // 触发头像更新事件
        const avatarUpdateEvent = new CustomEvent('avatarUpdate', {
          detail: { 
            avatar: userData.user_avatar,
            timestamp: new Date().getTime()
          }
        });
        window.dispatchEvent(avatarUpdateEvent);

        // 触发一个自定义事件通知用户登录状态变化
        const loginEvent = new CustomEvent('userLogin', {
          detail: userData
        });
        window.dispatchEvent(loginEvent);
        
        // 最后关闭模态框
        onClose();
      }
    } catch (error) {
      console.error('登录失败:', error);
    }
  };

  const handleRegisterClick = () => {
    onClose(); // 关闭登录模态框
    setIsRegisterOpen(true); // 打开注册模态框
  };

  // 添加 useEffect 处理模态框打开/关闭时的滚动条
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog 
        as="div" 
        className="relative z-10" 
        onClose={() => {}}  // 禁用背景点击关闭
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-[420px] transform overflow-hidden rounded-2xl bg-white p-10 text-left align-middle shadow-xl transition-all min-h-[560px] relative">
                <div className="flex justify-between items-center mb-12">
                  <div className="w-8 h-[20px]"></div>
                  <Dialog.Title
                    as="h3"
                    className="text-3xl font-medium text-center flex-grow"
                  >
                    登录
                  </Dialog.Title>
                  <button
                    onClick={onClose}
                    className="text-black w-8 h-[20px] leading-[20px] text-base"
                  >
                    关闭
                  </button>
                </div>

                <form onSubmit={handleSubmit} className="flex flex-col min-h-[320px] mt-12">
                  <div className="flex items-center space-x-4">
                    <label className="w-24 text-base text-black">您的邮箱</label>
                    <div className="flex-grow">
                      <input
                        type="email"
                        placeholder=""
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full rounded-full border border-black px-8 py-3 text-center"
                        required
                      />
                    </div>
                  </div>

                  <div className="flex items-center space-x-4 mt-12">
                    <label className="w-24 text-base text-black">您的密码</label>
                    <div className="flex-grow">
                      <input
                        type="password"
                        placeholder=""
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full rounded-full border border-black px-8 py-3 text-center"
                        required
                      />
                    </div>
                  </div>

                  <div className="flex justify-end mt-24">
                    <button
                      type="submit"
                      className="w-32 bg-[#0000ff] text-white rounded-full py-3 text-xl"
                    >
                      登录
                    </button>
                  </div>
                </form>

                <div className="absolute bottom-8 left-0 right-0 text-center">
                  <button
                    onClick={handleRegisterClick}
                    className="text-black hover:text-black"
                  >
                    没有注册？立即注册。
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default LoginModal;