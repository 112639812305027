import React, { useState, useEffect, useRef } from 'react';
import LoginModal from './LoginModal';
import RegisterModal from './RegisterModal';
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isRegisterOpen, setIsRegisterOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [userAvatar, setUserAvatar] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [isInitializing, setIsInitializing] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  // 添加 handleAvatarUpdate 函数
  const handleAvatarUpdate = (event) => {
    if (event.detail && event.detail.avatar) {
      setUserAvatar(event.detail.avatar);
      
      // 同时更新用户状态
      const userCookie = Cookies.get('user');
      if (userCookie) {
        const userData = JSON.parse(userCookie);
        userData.user_avatar = event.detail.avatar;
        setUser(userData);
        // 更新 cookie
        Cookies.set('user', JSON.stringify(userData));
      }
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const userCookie = Cookies.get('user');
      if (userCookie) {
        const userData = JSON.parse(userCookie);
        setUser(userData);
        
        // 确保头像 URL 是完整的
        if (userData.user_avatar) {
          const fullAvatarUrl = userData.user_avatar.startsWith('http')
            ? userData.user_avatar
            : `https://www.oiiet.com${userData.user_avatar}`;
          setUserAvatar(fullAvatarUrl);
        } else {
          // 如果 cookie 中没有头像，尝试从 profile 获取
          try {
            const response = await axios.get('/api/profiles/1/', {
              withCredentials: true
            });
            if (response.data && response.data.user_avatar) {
              const profileAvatarUrl = response.data.user_avatar.startsWith('http')
                ? response.data.user_avatar
                : `https://www.oiiet.com${response.data.user_avatar}`;
              setUserAvatar(profileAvatarUrl);
              
              // 更新 cookie 中的用户信息
              userData.user_avatar = profileAvatarUrl;
              Cookies.set('user', JSON.stringify(userData));
            } else {
              setUserAvatar('https://www.oiiet.com/static/avatar/default-avatar.png');
            }
          } catch (error) {
            console.error('获取用户头像失败:', error);
            setUserAvatar('https://www.oiiet.com/static/avatar/default-avatar.png');
          }
        }
      } else {
        setUser(null);
        setUserAvatar('https://www.oiiet.com/static/avatar/default-avatar.png');
      }
      setIsLoading(false);
      setIsInitializing(false);
      setIsMenuOpen(false);
    };

    fetchUserData();

    // 添加登录成功事件监听器
    const handleLoginSuccess = (event) => {
      if (event.detail && event.detail.user) {
        const userData = event.detail.user;
        setUser(userData);
        if (userData.user_avatar) {
          const fullAvatarUrl = userData.user_avatar.startsWith('http')
            ? userData.user_avatar
            : `https://www.oiiet.com${userData.user_avatar}`;
          setUserAvatar(fullAvatarUrl);
        }
      }
    };

    // 监听事件
    window.addEventListener('loginSuccess', handleLoginSuccess);
    window.addEventListener('avatarUpdate', handleAvatarUpdate);
    document.addEventListener('visibilitychange', fetchUserData);
    window.addEventListener('storage', fetchUserData);

    return () => {
      window.removeEventListener('loginSuccess', handleLoginSuccess);
      window.removeEventListener('avatarUpdate', handleAvatarUpdate);
      document.removeEventListener('visibilitychange', fetchUserData);
      window.removeEventListener('storage', fetchUserData);
    };
  }, []);

  // 监听路由变化
  useEffect(() => {
    setIsMenuOpen(false);
  }, [location.pathname]);

  // 修改点击外部关闭菜单的逻辑
  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    }

    // 使用 click 事件，并在捕获阶段处理
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const handleLogout = () => {
    Cookies.remove('user');
    setUser(null);
    setUserAvatar('https://www.oiiet.com/static/avatar/default-avatar.png');
    navigate('/');
  };

  const handleLoginClose = () => {
    setIsLoginOpen(false);
    setIsMenuOpen(false);
  };

  const handleRegisterClose = () => {
    setIsRegisterOpen(false);
    setIsMenuOpen(false);
  };

  return (
    <header className="max-w-screen-xl mx-auto w-full py-4 px-4 sm:px-6 flex items-center justify-between h-[72px] mt-4">
      <div className="w-24 sm:w-48"></div>

      <a 
        href="/" 
        className="text-4xl font-bold text-black absolute left-1/2 transform -translate-x-1/2"
        onClick={(e) => {
          e.preventDefault();
          window.location.href = '/';
        }}
        style={{ borderBottom: 'none' }}
      >
        Oiiet
      </a>

      <div className="w-24 sm:w-48 flex items-center justify-end">
        {isInitializing ? (
          <div className="w-8 sm:w-10 h-8 sm:h-10 rounded-full bg-gray-200 animate-pulse" />
        ) : !user ? (
          <div className="flex items-center justify-end space-x-2 sm:space-x-8">
            <button
              className="text-base text-black py-2"
              onClick={() => setIsRegisterOpen(true)}
            >
              注册
            </button>
            <button
              className="text-base text-black py-2"
              onClick={() => setIsLoginOpen(true)}
            >
              登录
            </button>
          </div>
        ) : (
          <div className="relative h-10" ref={menuRef}>
            <button
              className="w-10 h-10 rounded-full overflow-hidden"
              onClick={(e) => {
                e.stopPropagation(); // 阻止事件冒泡
                setIsMenuOpen(!isMenuOpen);
              }}
            >
              {isLoading ? (
                <div className="w-full h-full bg-gray-200 animate-pulse" />
              ) : (
                <img
                  src={userAvatar}
                  alt="用户头像"
                  className="w-full h-full object-cover"
                />
              )}
            </button>
            
            {isMenuOpen && (
              <div className="absolute right-0 mt-2 w-[160px] h-[140px] bg-white rounded-md shadow-lg py-1 z-20">
                <div className="px-4 py-2 text-base font-bold text-[#000000]">
                  嗨, {user.user_name}！
                </div>
                <a
                  href="/account"
                  className="block px-4 py-2 text-base font-bold text-[#000000]"
                >
                  Account
                </a>
                <button
                  onClick={handleLogout}
                  className="block w-full text-left px-4 py-2 text-base font-bold text-[#000000]"
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      <LoginModal 
        isOpen={isLoginOpen} 
        onClose={handleLoginClose}
        setUser={setUser}
        setIsRegisterOpen={setIsRegisterOpen}
      />
      <RegisterModal 
        isOpen={isRegisterOpen} 
        onClose={handleRegisterClose}
        setUser={setUser}
        setIsLoginOpen={setIsLoginOpen}
      />
    </header>
  );
}

export default Header;