import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

function Future() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Future</title>
        <meta name="description" content="未来的我们" />
      </Helmet>

      <div className="max-w-screen-xl mx-auto px-6 py-12">
        <h1 className="text-3xl font-bold mb-8">Future</h1>
        
        <div className="space-y-8 max-w-2xl">
          {/* 中文内容 */}
          <div className="text-base leading-relaxed space-y-4 text-[#000000]">
            <p>
              我们并未停滞，而是将之称为描述信仰的过程。
            </p>
            <p>
              我们重申生活的意义，并致力于开展具有指导性的合作。例如：与当地艺术家保持联系以及社区间组织的美食大赛。
            </p>
            <p>
              我们渴望达到一种平衡，同时也是一种平等。身处全新环境，我们将提供去中心化的人工智能，服务自然情境，以延伸和增强人类的智慧。
            </p>
          </div>

          {/* 英文内容 */}
          <div className="text-base leading-relaxed space-y-4 text-[#000000]">
            <p>
              We are not stagnant; rather, we view our progress as the process of expressing our beliefs.
            </p>
            <p>
              We reaffirm the meaning of life and are committed to engaging in purposeful collaborations—for example, maintaining connections with local artists and organizing culinary contests among communities.
            </p>
            <p>
              We aspire to achieve a balance that embodies equality. In this new environment, we will provide decentralized artificial intelligence to serve natural contexts, thereby extending and enhancing human wisdom.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Future;