import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import About from './components/About';
import Contact from './components/Contact';
import Future from './components/Future';
import Account from './pages/Account';
import Profiles from './pages/Profiles';
import Home from './pages/Home';
import ProductDetails from './pages/ProductDetails';
import Payment from './pages/Payment';
import PaymentSuccess from './pages/PaymentSuccess';
import PaymentCancel from './pages/PaymentCancel';
import Bills from './pages/Bills';
import OrderDetail from './pages/OrderDetail';
import NotFound from './components/NotFound';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <div className="min-h-screen flex flex-col"> 
          <Header />
          <main className="flex-grow">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/future" element={<Future />} />
              <Route path="/account" element={<Account />} />
              <Route path="/account/profiles" element={<Profiles />} />
              <Route path="/product/:productId" element={<ProductDetails />} />
              <Route path="/payment/:productId" element={<Payment />} />
              <Route path="/payment/success" element={<PaymentSuccess />} />
              <Route path="/payment/cancel" element={<PaymentCancel />} />
              <Route path="/account/bills" element={<Bills />} />
              <Route path="/account/bills/:orderId" element={<OrderDetail />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
