import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import Cookies from 'js-cookie';

// 添加默认配置
axios.defaults.baseURL = 'https://www.oiiet.com';
axios.defaults.withCredentials = true;

// 添加一个格式化时间的辅助函数
const formatDateTime = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return date.toLocaleString('zh-CN', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  });
};

function PaymentSuccess() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const orderId = searchParams.get('order_id');
  const tradeNo = searchParams.get('out_trade_no');
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [retryCount, setRetryCount] = useState(0);

  const fetchOrderDetails = useCallback(async () => {
    try {
      // 优先使用 order_id
      if (orderId) {
        const response = await axios.get(`/api/orders/detail/${orderId}/`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
          }
        });
        setOrderDetails(response.data);
        return;
      }

      // 如果没有 order_id，尝试使用 trade_no
      if (tradeNo) {
        const response = await axios.get(`/api/orders/trade/${tradeNo}/`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
          }
        });
        setOrderDetails(response.data);
      }
    } catch (error) {
      console.error('获取订单详情失败:', error);
      if (retryCount < 3) {
        setTimeout(() => {
          setRetryCount(prev => prev + 1);
        }, 3000);
      } else {
        setOrderDetails(null);
      }
    } finally {
      setLoading(false);
    }
  }, [orderId, tradeNo, retryCount]);

  useEffect(() => {
    fetchOrderDetails();
  }, [fetchOrderDetails]);

  // 加载状态
  if (loading) {
    return (
      <>
        <Helmet>
          <title>加载中...</title>
          <meta name="description" content="正在加载订单信息，请稍候..." />
        </Helmet>
        <div className="max-w-screen-xl mx-auto px-6 py-12">
          <div className="animate-pulse">
            <div className="h-8 bg-gray-200 rounded w-1/4 mb-8"></div>
            <div className="bg-gray-200 aspect-[16/9] rounded-2xl mb-8"></div>
            <div className="h-6 bg-gray-200 rounded w-3/4 mb-4"></div>
            <div className="h-4 bg-gray-200 rounded w-1/2 mb-8"></div>
            <div className="space-y-4">
              <div className="h-4 bg-gray-200 rounded w-full"></div>
              <div className="h-4 bg-gray-200 rounded w-5/6"></div>
            </div>
          </div>
        </div>
      </>
    );
  }

  // 错误状态
  if (!orderDetails) {
    return (
      <>
        <Helmet>
          <title>订单加载失败</title>
          <meta name="description" content="抱歉订单信息加载失败，该订单可能不存在或已被删除" />
        </Helmet>
        <div className="max-w-screen-xl mx-auto px-6 py-12">
          <div className="h-[360px] flex items-center justify-center">
            <div className="text-center">
              <h2 className="text-[30px] font-bold text-[#000000]">订单加载失败</h2>
              <p className="mt-2 text-[16px] text-[#000000]">该订单可能不存在或已被删除</p>
              <button 
                onClick={() => navigate('/account/bills')} 
                className="mt-4 px-4 py-2 bg-[#ff0000] text-[#ffffff] rounded-full hover:bg-[#ff0000] text-[20px]"
              >
                返回账单
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }

  console.log('准备渲染的订单详情数据:', orderDetails);

  return (
    <>
      <Helmet>
        <title>支付成功</title>
        <meta name="description" content={`感谢您支付${orderDetails.product.product_name}，支付金额为 ¥${orderDetails.order_price}`} />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <div className="max-w-screen-xl mx-auto px-6 py-12">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          {/* 左侧：商品图片展示 */}
          <div>
            {orderDetails?.product?.images?.length > 0 && (
              <div className="relative aspect-[4/3] rounded-2xl overflow-hidden">
                <img
                  src={orderDetails.product.images[0].image}
                  alt={orderDetails.product.product_name}
                  className="w-full h-full object-cover"
                />
              </div>
            )}
          </div>

          {/* 右侧：订单信息 */}
          <div className="relative min-h-[400px]">
            <div className="h-full">
              <div className="h-full flex flex-col">
                <div className="flex-1">
                  <h1 className="text-3xl font-bold text-[#000000] mb-4">
                    {orderDetails?.user_name ? `${orderDetails.user_name}！` : ''}感谢您，已支付成功。
                  </h1>
                  
                  <div className="w-full h-[1px] bg-gray-200 mb-6"></div>
                  
                  <div className="space-y-6">
                    <div>
                      <div className="space-y-3">
                        <p className="text-[20px] text-[#000000]">订单编号：{orderDetails?.order_id}</p>
                        <p className="text-[20px] text-[#000000]">订单名称：{orderDetails?.product?.product_name}</p>
                        <p className="text-[20px] text-[#000000]">支付金额：¥{orderDetails?.order_price}</p>
                        <p className="text-[20px] text-[#000000]">
                          创建时间：{formatDateTime(orderDetails?.order_create_time)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-8">
                  <div className="flex justify-end">
                    <button
                      onClick={() => navigate('/account/bills')}
                      className="w-32 bg-[#0000ff] text-[#ffffff] rounded-full py-3 text-[20px]"
                    >
                      查看账单
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentSuccess;