import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet-async';

// 设置 axios 默认配置
axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

function OrderDetail() {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showCompleteButton, setShowCompleteButton] = useState(false);
  const [localOrderState, setLocalOrderState] = useState(null);

  // 使用 useCallback 包装 fetchOrderDetails
  const fetchOrderDetails = useCallback(async () => {
    try {
      const response = await axios.get(`/api/orders/detail/${orderId}/`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': Cookies.get('csrftoken')
        }
      });
      setOrder(response.data);
      setLocalOrderState(response.data.order_state);
      setShowCompleteButton(response.data.order_state === 'processing');
    } catch (error) {
      console.error('获取订单详情失败:', error);
      // 根据错误状态码显示不同的错误信息
      if (error.response?.status === 403) {
        setOrder({ error: '没有权限访问此订单' });
      } else if (error.response?.status === 404) {
        setOrder({ error: '订单不存在' });
      } else {
        setOrder({ error: '获取订单详情失败' });
      }
    } finally {
      setLoading(false);
    }
  }, [orderId]);

  useEffect(() => {
    if (orderId) {
      fetchOrderDetails();
      const timer = setInterval(fetchOrderDetails, 10000);
      return () => clearInterval(timer);
    }
  }, [orderId, fetchOrderDetails]);

  const handleCompleteOrder = async () => {
    try {
      setLocalOrderState('completed');
      setShowCompleteButton(false);
      
      const response = await axios.post(
        `/api/orders/${orderId}/user-confirm-completion/`,
        {
          confirmation_time: new Date().toISOString()
        },
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
          },
          withCredentials: true
        }
      );
      
      if (response.data.message) {
        alert('感谢您的确认，我们已收到您的反馈');
      }
    } catch (error) {
      setLocalOrderState('processing');
      setShowCompleteButton(true);
      console.error('确认完成失败:', error.response || error);
      alert('确认失败，请稍后重试');
    }
  };

  if (loading) {
    return (
      <>
        <Helmet>
          <title>订单详情</title>
          <meta name="description" content="查看订单详情和状态" />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <div className="max-w-screen-xl mx-auto px-6 py-12">
          <div className="animate-pulse">
            <div className="h-8 bg-gray-200 rounded w-1/4 mb-8"></div>
            <div className="bg-gray-200 aspect-[16/9] rounded-2xl mb-8"></div>
            <div className="h-6 bg-gray-200 rounded w-3/4 mb-4"></div>
            <div className="h-4 bg-gray-200 rounded w-1/2 mb-8"></div>
            <div className="space-y-4">
              <div className="h-4 bg-gray-200 rounded w-full"></div>
              <div className="h-4 bg-gray-200 rounded w-5/6"></div>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (order?.error) {
    return (
      <>
        <Helmet>
          <title>订单详情</title>
          <meta name="description" content="查看订单详情和状态" />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <div className="max-w-screen-xl mx-auto px-6 py-12">
          <div className="h-[360px] flex items-center justify-center">
            <div className="text-center">
              <h2 className="text-[30px] font-bold text-[#000000]">{order.error}</h2>
              <p className="mt-2 text-[16px] text-[#000000]">请确认您是否有权限访问此订单</p>
              <button 
                onClick={() => navigate('/account/bills')} 
                className="mt-4 px-4 py-2 bg-[#ff0000] text-[#ffffff] rounded-full hover:bg-[#ff0000] text-[20px]"
              >
                返回账单
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (!order) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>订单详情</title>
        <meta name="description" content="查看订单详情和状态" />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="max-w-screen-xl mx-auto px-6 py-12">
        <div className="font-bold mb-8">
          <span 
            className="cursor-pointer text-[30px]"
            onClick={() => navigate('/account/bills')}
          >
            返回
          </span>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 mb-20">
          {/* 左侧：图片展示区 */}
          <div>
            <div className="relative aspect-[4/3] rounded-2xl overflow-hidden">
              {order?.product?.images?.length > 0 ? (
                <img
                  src={order.product.images[0].image}
                  alt={order.order_name}
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="absolute w-full h-full bg-gray-200 flex items-center justify-center">
                  <span className="text-gray-400">暂无图片</span>
                </div>
              )}
            </div>
          </div>

          {/* 右侧：订单信息 - 修改容器样式 */}
          <div className="relative min-h-[400px]">
            <div className="h-full">
              <div className="flex flex-col h-full">
                <div className="flex-1">
                  <h1 className="text-[30px] font-bold text-[#000000] mb-4">
                    {order.order_name}
                  </h1>

                  <div className="w-full h-[1px] bg-gray-200 mb-6"></div>

                  {/* 订单信息 */}
                  <div className="space-y-6">
                    <div className="space-y-3">
                      <p className="text-[20px] text-[#000000]">订单编号：{order.order_id}</p>
                      <p className="text-[20px] text-[#000000]">支付金额：¥{order.order_price}</p>
                      {order.payment_time && (
                        <p className="text-[20px] text-[#000000]">支付时间：{new Date(order.payment_time).toLocaleString()}</p>
                      )}
                    </div>

                    {/* 处理信息区域 */}
                    {order.processing_note && ((localOrderState || order.order_state) === 'processing' || (localOrderState || order.order_state) === 'completed') && (
                      <div className="space-y-3">
                        <div className="flex items-start text-[20px] text-[#000000]">
                          <div>
                            <span className="font-semibold">处理信息：</span>
                            <span className="break-words whitespace-pre-line">{order.processing_note}</span>
                          </div>
                        </div>
                        {order.processing_time && (
                          <p className="text-[20px] text-[#000000]">处理时间：{new Date(order.processing_time).toLocaleString()}</p>
                        )}
                      </div>
                    )}

                    {/* 订单状态和完成时间 */}
                    <div className="space-y-3">
                      <p className="text-[20px] text-[#000000]">
                        订单状态：
                        <span className="text-[20px] text-[#000000]">
                          {
                            {
                              'payment_completed': '支付完成',
                              'processing': '正在处理',
                              'completed': '订单完成'
                            }[localOrderState || order.order_state]
                          }
                        </span>
                      </p>
                      {((localOrderState || order.order_state) === 'completed' && order.completion_time) && (
                        <p className="text-[20px] text-[#000000]">完成时间：{new Date(order.completion_time).toLocaleString()}</p>
                      )}
                    </div>
                  </div>
                </div>

                {/* 完成按钮 - 调整定位 */}
                {showCompleteButton && (
                  <div className="mt-6">
                    <div className="flex justify-end">
                      <button
                        onClick={handleCompleteOrder}
                        className="w-32 bg-[#ff0000] text-white rounded-full py-3 hover:bg-[#ff0000] text-[20px]"
                      >
                        订单完成
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderDetail;