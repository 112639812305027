import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';

// 设置 axios 默认配置
axios.defaults.baseURL = 'https://www.oiiet.com';
axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

function Profiles() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState({
    user_avatar: undefined,
    user_saying: '',
    user_email: '',
    user_name: '',
    user_phone_number: '',
    user_address: ''
  });
  const [previewImage, setPreviewImage] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [isEditingPhone, setIsEditingPhone] = useState(false);
  const [isEditingAddress, setIsEditingAddress] = useState(false);
  const [tempPhone, setTempPhone] = useState('');
  const [tempAddress, setTempAddress] = useState('');

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    
    const handleBeforeUnload = () => {
      window.scrollTo(0, 0);
    };
    
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // 获取用户信息和个人资料
    const fetchProfileData = async () => {
      try {
        const userCookie = Cookies.get('user');
        if (!userCookie) {
          navigate('/');
          return;
        }

        const userData = JSON.parse(userCookie);
        setUser(userData);

        const response = await axios.get('/api/profiles/1/', {
          withCredentials: true
        });

        if (response.data) {
          setProfile(response.data);
          // 统一处理头像 URL
          const timestamp = new Date().getTime();
          const avatarUrl = response.data.user_avatar;
          let fullAvatarUrl;
          
          if (avatarUrl) {
            if (!avatarUrl.startsWith('http')) {
              fullAvatarUrl = `https://www.oiiet.com${avatarUrl}`;
            } else {
              fullAvatarUrl = avatarUrl;
            }
            // 添加时间戳
            fullAvatarUrl = `${fullAvatarUrl}${fullAvatarUrl.includes('?') ? '&' : '?'}t=${timestamp}`;
          } else {
            fullAvatarUrl = `https://www.oiiet.com/static/avatar/default-avatar.png?t=${timestamp}`;
          }
          
          setPreviewImage(fullAvatarUrl);

          // 更新全局头像状态
          const avatarUpdateEvent = new CustomEvent('avatarUpdate', {
            detail: { 
              avatar: fullAvatarUrl,
              timestamp: timestamp
            }
          });
          window.dispatchEvent(avatarUpdateEvent);
        }
      } catch (error) {
        console.error('获取数据失败:', error);
        setPreviewImage('https://www.oiiet.com/static/avatar/default-avatar.png');
        navigate('/');
      } finally {
        setLoading(false);
      }
    };

    fetchProfileData();
  }, [navigate]);

  // 处理头像更改
  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // 检查文件大小
      if (file.size > 50 * 1024 * 1024) { // 50MB 限制
        alert('头像文件大小不能超过 50MB');
        return;
      }

      // 更新预览图片
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);

      // 更新 profile 状态
      setProfile({
        ...profile,
        user_avatar: file
      });
    }
  };

  // 处理保存
  const handleSave = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      
      if (profile.user_avatar instanceof File) {
        // 检查文件大小和类型
        if (profile.user_avatar.size > 5 * 1024 * 1024) {
          throw new Error('头像文件大小不能超过 5MB');
        }
        const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
        if (!allowedTypes.includes(profile.user_avatar.type)) {
          throw new Error('只支持 JPG、PNG 和 GIF 格式的图片');
        }
        formData.append('user_avatar', profile.user_avatar);
      }
      
      formData.append('user_saying', profile.user_saying || '');
      formData.append('user_phone_number', profile.user_phone_number || '');
      formData.append('user_address', profile.user_address || '');

      const response = await axios.patch('/api/profiles/1/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-CSRFToken': Cookies.get('csrftoken'),
        },
        withCredentials: true,
      });

      if (response.data) {
        const timestamp = new Date().getTime();
        let avatarUrl = response.data.user_avatar;
        
        // 统一处理头像 URL
        if (avatarUrl) {
          if (!avatarUrl.startsWith('http')) {
            avatarUrl = `https://www.oiiet.com${avatarUrl}`;
          } else {
            avatarUrl = avatarUrl;
          }
          // 添加时间戳
          avatarUrl = `${avatarUrl}${avatarUrl.includes('?') ? '&' : '?'}t=${timestamp}`;
        }

        // 更新状态
        setProfile(prev => ({
          ...prev,
          ...response.data,
          user_avatar: avatarUrl
        }));
        setPreviewImage(avatarUrl);

        // 强制刷新图片
        const img = new Image();
        img.src = avatarUrl;

        // 更新全局头像状态
        const avatarUpdateEvent = new CustomEvent('avatarUpdate', {
          detail: { 
            avatar: avatarUrl,
            timestamp: timestamp
          }
        });
        window.dispatchEvent(avatarUpdateEvent);

        alert('保存成功！');
      }
    } catch (error) {
      console.error('保存失败:', error);
      let errorMessage = '保存失败，请重试';
      if (error.response) {
        errorMessage = error.response.data.error || errorMessage;
      } else if (error.request) {
        errorMessage = '无法连接到服务器，请检查网络连接';
      } else {
        errorMessage = error.message || errorMessage;
      }
      alert(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handlePhoneSave = async () => {
    // 验证手机号
    if (!/^1[3-9]\d{9}$/.test(tempPhone)) {
      alert('请输入正确的11位手机号码');
      return;
    }

    try {
      setLoading(true);
      const response = await axios.patch('/api/profiles/1/', 
        { user_phone_number: tempPhone },
        {
          headers: {
            'X-CSRFToken': Cookies.get('csrftoken'),
          },
          withCredentials: true,
        }
      );
      if (response.data) {
        setProfile(prev => ({ ...prev, user_phone_number: tempPhone }));
        setIsEditingPhone(false);
      }
    } catch (error) {
      console.error('保存电话失败:', error);
      alert('保存失败，请重试');
    } finally {
      setLoading(false);
    }
  };

  const handleAddressSave = async () => {
    // 验证地址长度
    if (tempAddress.length < 3) {
      alert('地址不能少于3个字符');
      return;
    }

    try {
      setLoading(true);
      const response = await axios.patch('/api/profiles/1/', 
        { user_address: tempAddress },
        {
          headers: {
            'X-CSRFToken': Cookies.get('csrftoken'),
          },
          withCredentials: true,
        }
      );
      if (response.data) {
        setProfile(prev => ({ ...prev, user_address: tempAddress }));
        setIsEditingAddress(false);
      }
    } catch (error) {
      console.error('保存地址失败:', error);
      alert('保存失败，请重试');
    } finally {
      setLoading(false);
    }
  };

  const handlePhoneDelete = async () => {
    try {
      setLoading(true);
      const response = await axios.patch('/api/profiles/1/', 
        { user_phone_number: '' },
        {
          headers: {
            'X-CSRFToken': Cookies.get('csrftoken'),
          },
          withCredentials: true,
        }
      );
      if (response.data) {
        setProfile(prev => ({ ...prev, user_phone_number: '' }));
        setTempPhone('');
      }
    } catch (error) {
      console.error('删除电话失败:', error);
      alert('删除失败，请重试');
    } finally {
      setLoading(false);
    }
  };

  const handleAddressDelete = async () => {
    try {
      setLoading(true);
      const response = await axios.patch('/api/profiles/1/', 
        { user_address: '' },
        {
          headers: {
            'X-CSRFToken': Cookies.get('csrftoken'),
          },
          withCredentials: true,
        }
      );
      if (response.data) {
        setProfile(prev => ({ ...prev, user_address: '' }));
        setTempAddress('');
      }
    } catch (error) {
      console.error('删除地址失败:', error);
      alert('删除失败，请重试');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>账户信息</title>
        <meta name="description" content="查看和修改您的账户信息" />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <div className="max-w-screen-xl mx-auto px-6 py-12">
        <div className="text-[30px] font-bold mb-8">
          嗨，{user?.user_name}！您的账户：
        </div>

        <div className="flex flex-col lg:flex-row lg:space-x-8 mt-16">
          {/* 左侧部分：头像 - 在小屏幕上居中显示 */}
          <div className="w-full lg:w-96 flex flex-col items-center mb-12 lg:mb-0">
            <div className="relative w-40 h-40 cursor-pointer mb-4">
              {loading ? (
                <div className="w-full h-full rounded-full bg-gray-200 animate-pulse" />
              ) : (
                <img
                  src={previewImage}
                  alt="头像"
                  className="w-full h-full object-cover rounded-full"
                />
              )}
              <input
                type="file"
                accept="image/*"
                onChange={handleAvatarChange}
                className="absolute inset-0 opacity-0 cursor-pointer"
              />
            </div>
            <span
              onClick={() => document.querySelector('input[type="file"]').click()}
              className="text-[16px] text-[#000000] cursor-pointer"
            >
              修改头像
            </span>
          </div>

          {/* 右侧部分：用户信息和Saying - 在小屏幕上占满宽度 */}
          <div className="flex-1 lg:pl-8">
            <div className="text-[16px] leading-relaxed text-[#000000]">
              <div className="flex flex-col space-y-6">
                <div className="w-full lg:w-[90%] lg:ml-auto">
                  <span className="mr-2">您的名字：</span>
                  <span>{user?.user_name}</span>
                </div>
                
                <div className="w-full lg:w-[90%] lg:ml-auto">
                  <span className="mr-2">您的邮箱：</span>
                  <span>{profile.user_email}</span>
                </div>
                
                <div className="w-full lg:w-[90%] lg:ml-auto mb-6">
                  <span className="mr-2">您的ID：</span>
                  <span>{user?.user_id}</span>
                </div>

                <div className="w-full lg:w-[90%] lg:ml-auto">
                  <div className="flex justify-between items-center">
                    <div className="text-[#000000] max-w-[calc(100%-60px)]">
                      <span className="mr-2">您的电话：</span>
                      {isEditingPhone ? (
                        <input
                          type="tel"
                          pattern="[0-9]*"
                          maxLength={11}
                          value={tempPhone}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d*$/.test(value) && value.length <= 11) {
                              setTempPhone(value);
                            }
                          }}
                          className="border-2 border-[#000000] px-3 py-1 rounded-full text-center text-[16px]"
                        />
                      ) : (
                        <span className="break-all">
                          {profile.user_phone_number}
                        </span>
                      )}
                    </div>
                    {isEditingPhone ? (
                      <div className="flex space-x-6">
                        <button
                          onClick={() => setIsEditingPhone(false)}
                          className="text-[#000000] text-[16px]"
                        >
                          取消
                        </button>
                        <button
                          onClick={handlePhoneSave}
                          className="text-[#000000] text-[16px]"
                        >
                          保存
                        </button>
                      </div>
                    ) : (
                      <button
                        onClick={profile.user_phone_number ? handlePhoneDelete : () => {
                          setTempPhone('');
                          setIsEditingPhone(true);
                        }}
                        className="text-[#000000] text-[16px]"
                      >
                        {profile.user_phone_number ? '删除' : '添加'}
                      </button>
                    )}
                  </div>
                </div>

                <div className="w-full lg:w-[90%] lg:ml-auto">
                  <div className="flex justify-between items-center">
                    <div className="text-[#000000] max-w-[calc(100%-60px)]">
                      <span className="mr-2">您的地址：</span>
                      {isEditingAddress ? (
                        <input
                          type="text"
                          value={tempAddress}
                          onChange={(e) => setTempAddress(e.target.value)}
                          className="border-2 border-[#000000] px-3 py-1 rounded-full text-center text-[16px]"
                        />
                      ) : (
                        <span className="break-all">
                          {profile.user_address}
                        </span>
                      )}
                    </div>
                    {isEditingAddress ? (
                      <div className="flex space-x-6">
                        <button
                          onClick={() => setIsEditingAddress(false)}
                          className="text-[#000000] text-[16px]"
                        >
                          取消
                        </button>
                        <button
                          onClick={handleAddressSave}
                          className="text-[#000000] text-[16px]"
                        >
                          保存
                        </button>
                      </div>
                    ) : (
                      <button
                        onClick={profile.user_address ? handleAddressDelete : () => {
                          setTempAddress('');
                          setIsEditingAddress(true);
                        }}
                        className="text-[#000000] text-[16px]"
                      >
                        {profile.user_address ? '删除' : '添加'}
                      </button>
                    )}
                  </div>
                </div>

                <div className="w-full lg:w-[90%] lg:ml-auto">
                  <div className="mb-2 text-[16px]">您有什么话想对我们说：</div>
                  <textarea
                    value={profile.user_saying || ''}
                    onChange={(e) => setProfile({...profile, user_saying: e.target.value})}
                    className="w-full rounded-2xl border-2 border-[#000000] px-6 py-4 text-[16px] resize-y min-h-[120px] max-h-[240px]"
                    placeholder=""
                    rows="6"
                  />
                </div>

                <div className="w-full lg:w-[90%] lg:ml-auto">
                  <div className="flex justify-end">
                    <button
                      className={`bg-[#0000ff] text-white px-8 py-2 rounded-full transition-colors text-[20px] ${
                        loading ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                      onClick={handleSave}
                      disabled={loading}
                    >
                      {loading ? '保存中...' : '保存'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profiles; 