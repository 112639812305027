import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet-async';

function Bills() {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const userCookie = Cookies.get('user');
    if (userCookie) {
      setUser(JSON.parse(userCookie));
    }
    
    const fetchOrders = async () => {
      try {
        const response = await axios.get('/api/orders/', {
          withCredentials: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
          }
        });
        
        const sortedOrders = response.data.sort((a, b) => 
          new Date(b.order_create_time) - new Date(a.order_create_time)
        );
        
        setOrders(sortedOrders);
      } catch (error) {
        console.error('获取订单失败:', error);
        if (error.response?.status === 403) {
          navigate('/account');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [navigate]);

  const getStatusDisplay = (status) => {
    const statusMap = {
      'payment_completed': '支付完成',
      'processing': '正在处理',
      'completed': '订单完成'
    };
    return statusMap[status] || status;
  };

  const getStatusColor = (status) => {
    const colorMap = {
      'payment_completed': 'text-[#ff0000]',
      'processing': 'text-[#0000ff]',
      'completed': 'text-[#000000]'
    };
    return colorMap[status] || 'text-[#000000]';
  };

  if (loading) {
    return (
      <div className="max-w-screen-xl mx-auto px-6 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16">
          {[1, 2, 3, 4, 5, 6].map((index) => (
            <div key={index} className="animate-pulse">
              <div className="bg-gray-200 aspect-[4/3] rounded-2xl mb-4"></div>
              <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
              <div className="h-4 bg-gray-200 rounded w-1/2"></div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>账单信息</title>
        <meta name="description" content="查看您的账单记录和订单状态" />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <div className="max-w-screen-xl mx-auto px-6 py-12">
        <div className="text-[30px] font-bold mb-8">
          嗨，{user?.user_name}！{orders.length > 0 ? '您的账单：' : '您暂无账单。'}
        </div>
        {orders.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16">
            {orders.map(order => (
              <div key={order.order_id} className="bg-white">
                <Link 
                  to={`/account/bills/${order.order_id}`}
                  className="block relative aspect-[4/3] w-full cursor-pointer select-auto"
                >
                  {order.product?.images && order.product.images.length > 0 ? (
                    <img
                      src={order.product.images[0].image}
                      alt={order.order_name}
                      className="absolute w-full h-full object-cover rounded-[24px]"
                    />
                  ) : (
                    <div className="absolute w-full h-full bg-gray-200 flex items-center justify-center rounded-[24px]">
                      <span className="text-gray-400">暂无图片</span>
                    </div>
                  )}
                </Link>

                <div className="mt-4 px-1">
                  <div className="select-text">
                    <h3 className="text-xl font-bold text-[#000000] mb-2">
                      {order.order_name}
                    </h3>
                  </div>
                  
                  <div className="flex justify-end select-text">
                    <Link to={`/account/bills/${order.order_id}`}>
                      <span 
                        className={`
                          inline-block w-40 text-[20px] font-normal px-6 py-3 rounded-full text-center
                          ${getStatusColor(order.order_state)}
                          border-2 border-current
                        `}
                      >
                        {getStatusDisplay(order.order_state)}
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </>
  );
}

export default Bills;