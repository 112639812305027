import React, { useState, useEffect, useLayoutEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

// 设置 axios 默认配置
axios.defaults.baseURL = 'https://www.oiiet.com';
axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

function Home() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    
    const handleBeforeUnload = () => {
      window.scrollTo(0, 0);
    };
    
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get('/api/products/');
        console.log('Products response:', response.data);
        setProducts(response.data);
      } catch (error) {
        console.error('获取产品列表失败:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleProductClick = (productId) => {
    window.scrollTo(0, 0);
    navigate(`/product/${productId}`);
  };

  return (
    <>
      <Helmet>
        <title>Oiiet</title>
        <meta name="description" content="首页" />
      </Helmet>

      <div className="max-w-screen-xl mx-auto px-6 py-12">
        {loading ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16">
            {[1, 2, 3, 4, 5, 6].map((index) => (
              <div key={index} className="animate-pulse">
                <div className="bg-gray-200 aspect-[4/3] rounded-2xl mb-4"></div>
                <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
                <div className="h-4 bg-gray-200 rounded w-1/2"></div>
              </div>
            ))}
          </div>
        ) : (
          <>
            <div className="text-3xl font-bold mb-8">创造Create</div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16">
              {products.map((product) => (
                <div key={product.product_id}>
                  <div className="bg-white">
                    <div 
                      className="relative aspect-[4/3] w-full cursor-pointer select-auto"
                      onClick={() => handleProductClick(product.product_id)}
                    >
                      {product.images && product.images.length > 0 ? (
                        <img
                          src={product.images[0].image}
                          alt={product.product_name}
                          className="absolute w-full h-full object-cover rounded-[24px]"
                        />
                      ) : (
                        <div className="absolute w-full h-full bg-gray-200 flex items-center justify-center rounded-[24px]">
                          <span className="text-gray-400">暂无图片</span>
                        </div>
                      )}
                    </div>

                    <div className="mt-4 px-1">
                      <div className="select-text">
                        <h3 className="text-xl font-bold text-[#000000] mb-2">
                          {product.product_name}
                        </h3>
                        <p className="text-[#000000] mb-2 line-clamp-2 overflow-hidden">
                          {product.product_introduce}
                        </p>
                      </div>
                      <div className="flex justify-end select-text">
                        <span className="text-xl font-bold text-[#000000]">
                          ¥{product.product_price}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Home; 