import React from 'react';
import { useNavigate } from 'react-router-dom';

function Footer() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <footer className="max-w-screen-xl mx-auto w-full px-4 sm:px-6 pb-6 pt-12 sm:pt-24">
      <div className="flex justify-center space-x-8 sm:space-x-24 mb-8 mt-12">
        <button
          onClick={() => handleNavigation('/about')}
          className="text-[#000000] text-base font-bold whitespace-nowrap"
        >
          About
        </button>
        <button
          onClick={() => handleNavigation('/contact')}
          className="text-[#000000] text-base font-bold whitespace-nowrap"
        >
          Contact
        </button>
        <button
          onClick={() => handleNavigation('/future')}
          className="text-[#000000] text-base font-bold whitespace-nowrap"
        >
          Future
        </button>
      </div>

      <div className="text-center text-base text-[#000000]">
        © Oiiet. All rights reserved.
      </div>
    </footer>
  );
}

export default Footer;