import React, { useState, useEffect, useLayoutEffect, useCallback, Fragment } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Dialog, Transition } from '@headlessui/react';
import { Helmet } from 'react-helmet-async';
import Cookies from 'js-cookie';

axios.defaults.baseURL = 'https://www.oiiet.com';
axios.defaults.withCredentials = true;

function Payment() {
  const { productId } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [profile, setProfile] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [isCheckingPayment, setIsCheckingPayment] = useState(false);
  const [paymentCheckTimer, setPaymentCheckTimer] = useState(null);
  const [tradeNo, setTradeNo] = useState(null);
  const [paymentWindow, setPaymentWindow] = useState(null);

  const checkPaymentStatus = useCallback(async (tradeNo) => {
    try {
      console.log('开始检查支付状态:', tradeNo);
      const response = await axios.get(`/api/alipay/check/${tradeNo}/`, {
        withCredentials: true,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': Cookies.get('csrftoken')
        }
      });
      
      console.log('支付状态检查结果:', response.data);
      
      if (response.data.paid || 
          response.data.order_state === 'payment_completed' || 
          response.data.trade_status === 'TRADE_SUCCESS') {
        console.log('支付成功，准备跳转');
        // 清理定时器
        if (paymentCheckTimer) {
          clearInterval(paymentCheckTimer);
          setPaymentCheckTimer(null);
        }
        if (response.data.order_id) {
          window.location.href = `/payment/success?order_id=${response.data.order_id}`;
        } else {
          window.location.href = `/payment/success?out_trade_no=${tradeNo}`;
        }
        return true;
      }
      return false;
    } catch (error) {
      console.error('检查支付状态失败:', error);
      return false;
    }
  }, [paymentCheckTimer]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    
    const handleBeforeUnload = () => {
      window.scrollTo(0, 0);
    };
    
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // 获取商品信息
        const productResponse = await axios.get(`/api/products/${productId}/`);
        setProduct(productResponse.data);

        // 获取用户资料
        const profileResponse = await axios.get('/api/profiles/1/');
        setProfile(profileResponse.data);
      } catch (error) {
        console.error('获取数据失败:', error);
        setProduct(null);  // 设置为 null 显示自定义错误页面
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [productId]);

  useEffect(() => {
    const checkAuthAndProfile = async () => {
      try {
        // 首先检查用户是否登录
        const userCookie = Cookies.get('user');
        if (!userCookie) {
          console.log('用户未登录，跳转到登录页面');
          navigate('/account');
          return;
        }

        // 获取用户资料
        const profileResponse = await axios.get('/api/profiles/1/', {
          withCredentials: true
        });
        
        const userProfile = profileResponse.data;
        
        // 检查用户是否填写了电话和地址
        if (!userProfile?.user_phone_number || !userProfile?.user_address) {
          console.log('缺少联系信息，跳转回商品详情页');
          navigate(`/product/${productId}`);
          return;
        }

        setProfile(userProfile);
        
      } catch (error) {
        console.error('验证失败:', error);
        navigate('/account');
      }
    };

    checkAuthAndProfile();
  }, [navigate, productId]);

  useEffect(() => {
    if (showPaymentModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [showPaymentModal]);

  useEffect(() => {
    if (isLightboxOpen) {
      // 禁用滚动
      document.body.style.overflow = 'hidden';
      // 防止iOS设备的弹性滚动
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
      document.body.style.top = `-${window.scrollY}px`;
    } else {
      // 恢复滚动
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.width = '';
      document.body.style.top = '';
      document.body.style.overflow = 'unset';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }

    // 清理函数
    return () => {
      document.body.style.overflow = 'unset';
      document.body.style.position = '';
      document.body.style.width = '';
      document.body.style.top = '';
    };
  }, [isLightboxOpen]);

  useEffect(() => {
    const handleAlipayReturn = (event) => {
      if (window.location.href.includes('alipay_trade_no')) {
        const urlParams = new URLSearchParams(window.location.search);
        const tradeNo = urlParams.get('alipay_trade_no');
        if (tradeNo) {
          checkPaymentStatus(tradeNo);
        }
      }
    };

    const handleMessage = (event) => {
      if (event.data && event.data.type === 'alipayCallback') {
        if (paymentCheckTimer) {
          clearInterval(paymentCheckTimer);
        }
        setIsCheckingPayment(false);
        setShowPaymentModal(false);
        navigate(`/payment/success?out_trade_no=${event.data.orderId}`);
      }
    };

    window.addEventListener('message', handleMessage);
    window.addEventListener('popstate', handleAlipayReturn);

    return () => {
      window.removeEventListener('message', handleMessage);
      window.removeEventListener('popstate', handleAlipayReturn);
    };
  }, [navigate, paymentCheckTimer, checkPaymentStatus]);

  const handlePayment = async () => {
    // 先显示确认模态框
    setShowPaymentModal(true);
  };

  const handleConfirmPayment = async () => {
    try {
      setIsProcessingPayment(true);
      
      const response = await axios.post(
        '/api/alipay/create/',
        { product_id: productId },
        { 
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
          }
        }
      );

      if (response.data.pay_url) {
        const currentTradeNo = response.data.trade_no;
        setTradeNo(currentTradeNo);
        setIsCheckingPayment(true);
        
        // 保存支付宝窗口引用
        const newPaymentWindow = window.open(response.data.pay_url, '_blank');
        setPaymentWindow(newPaymentWindow);
        
        // 开始检查支付状态
        const timer = setInterval(async () => {
          try {
            if (!newPaymentWindow || newPaymentWindow.closed) {
              console.log('支付窗口已关闭，执行最终检查');
              const isSuccess = await checkPaymentStatus(currentTradeNo);
              if (!isSuccess) {
                clearInterval(timer);
                setPaymentCheckTimer(null);
                setIsCheckingPayment(false);
                setShowPaymentModal(false);
              }
              return;
            }

            console.log('定时检查支付状态:', currentTradeNo);
            const isSuccess = await checkPaymentStatus(currentTradeNo);
            if (isSuccess && newPaymentWindow) {
              newPaymentWindow.close();
            }
          } catch (error) {
            console.error('检查支付状态失败:', error);
          }
        }, 2000);
        
        setPaymentCheckTimer(timer);
      }
    } catch (error) {
      console.error('创建支付失败:', error);
      alert(`创建支付失败: ${error.response?.data?.error || '请重试'}`);
    } finally {
      setIsProcessingPayment(false);
    }
  };

  const handleManualCheck = async () => {
    if (!tradeNo) return;
    
    try {
      const response = await axios.get(`/api/alipay/check/${tradeNo}/`);
      if (response.data.paid) {
        if (paymentCheckTimer) {
          clearInterval(paymentCheckTimer);
        }
        setIsCheckingPayment(false);
        setShowPaymentModal(false);
        navigate(`/payment/success?out_trade_no=${tradeNo}`);
      } else {
        alert('未检测到支付完成，请确认支付状态或重试');
      }
    } catch (error) {
      console.error('检查支付状态失败:', error);
      alert('检查支付状态失败，请重试');
    }
  };

  useEffect(() => {
    return () => {
      if (paymentCheckTimer) {
        console.log('清理支付检查定时器');
        clearInterval(paymentCheckTimer);
      }
    };
  }, [paymentCheckTimer]);

  const handleCloseLightbox = (e) => {
    if (e.target === e.currentTarget) {
      setIsLightboxOpen(false);
    }
  };

  const handleImageChange = (direction) => {
    if (!product || !product.images) return;
    
    if (direction === 'next') {
      setCurrentImageIndex((prev) => 
        prev === product.images.length - 1 ? 0 : prev + 1
      );
    } else {
      setCurrentImageIndex((prev) => 
        prev === 0 ? product.images.length - 1 : prev - 1
      );
    }
  };

  const handleClosePaymentModal = () => {
    setShowPaymentModal(false);
    // 跳转到取消支付页面，并传递商品 ID
    navigate(`/payment/cancel?product_id=${productId}`);
  };

  const handleCancelPayment = async () => {
    if (isProcessingPayment) return;
    
    try {
      setIsProcessingPayment(true);
      
      // 尝试关闭支付窗口
      if (paymentWindow && !paymentWindow.closed) {
        paymentWindow.close();
      }

      if (tradeNo) {
        await axios.post(`/api/alipay/cancel/${tradeNo}/`, {}, {
          withCredentials: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': Cookies.get('csrftoken')
          }
        });
      }

      // 清理定时器
      if (paymentCheckTimer) {
        clearInterval(paymentCheckTimer);
        setPaymentCheckTimer(null);
      }

      setIsCheckingPayment(false);
      navigate(`/payment/cancel?product_id=${productId}`);
    } catch (error) {
      console.error('取消支付失败:', error);
    } finally {
      setIsProcessingPayment(false);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = async (event) => {
      if (tradeNo) {
        try {
          // 尝试关闭支付窗口
          if (paymentWindow && !paymentWindow.closed) {
            paymentWindow.close();
          }

          // 调用取消支付接口
          await axios.post(`/api/alipay/cancel/${tradeNo}/`, {}, {
            withCredentials: true,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-CSRFToken': Cookies.get('csrftoken')
            }
          });

          // 清理定时器
          if (paymentCheckTimer) {
            clearInterval(paymentCheckTimer);
          }

          // 重定向到支付取消页面，并携带产品ID
          window.location.href = `/payment/cancel?product_id=${productId}`;
        } catch (error) {
          console.error('取消支付失败:', error);
        }
      }
    };

    // 添加页面刷新/关闭事件监听
    window.addEventListener('beforeunload', handleBeforeUnload);

    // 组件卸载时清理
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      if (tradeNo) {
        handleBeforeUnload();  // 在组件卸载时也执行清理操作
      }
    };
  }, [tradeNo, paymentCheckTimer, paymentWindow, productId]);

  if (loading) {
    return (
      <>
        <Helmet>
          <title>订单加载中...</title>
          <meta name="description" content="正在加载订单信息，请稍候..." />
        </Helmet>
        <div className="fixed inset-0 flex items-center justify-center">
          <div className="text-2xl hover:opacity-75">加载中...</div>
        </div>
      </>
    );
  }

  if (!product) {
    return (
      <>
        <Helmet>
          <title>订单加载失败</title>
          <meta name="description" content="抱歉订单信息加载失败，该产品可能不存在或已被删除" />
        </Helmet>
        <div className="max-w-screen-xl mx-auto px-6 py-12">
          <div className="h-[360px] flex items-center justify-center">
            <div className="text-center">
              <h2 className="text-[30px] font-bold text-[#000000]">支付页面加载失败</h2>
              <p className="mt-2 text-[16px] text-[#000000]">该产品可能不存在或已被删除</p>
              <button 
                onClick={() => navigate('/')} 
                className="mt-4 px-4 py-2 bg-[#ff0000] text-[#ffffff] rounded-full hover:bg-[#ff0000] text-[20px]"
              >
                返回首页
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{`${product.product_name}`}</title>
        <meta name="description" content={`确认购买 ${product.product_name}，请核对订单信息并完成支付`} />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <div className="max-w-screen-xl mx-auto px-6 py-12">
        <div className="text-3xl font-bold mb-8">
          <span 
            className="cursor-pointer text-3xl"
            onClick={() => navigate(`/product/${productId}`)}
          >
            返回
          </span>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          {/* 左侧：商品图片展示 */}
          <div>
            <div 
              className="relative aspect-[4/3] rounded-2xl overflow-hidden cursor-pointer"
              onClick={() => setIsLightboxOpen(true)}
            >
              {product?.images && product.images.length > 0 ? (
                <img
                  src={product.images[currentImageIndex].image}
                  alt={product.product_name}
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="w-full h-full bg-gray-200" />
              )}
            </div>
            
            {/* 缩略图 */}
            <div className="mt-4 grid grid-cols-4 gap-4">
              {product?.images?.map((image, index) => (
                <div
                  key={index}
                  className={`aspect-square rounded-lg overflow-hidden cursor-pointer ${
                    currentImageIndex === index ? 'ring-2 ring-black' : ''
                  }`}
                  onClick={() => setCurrentImageIndex(index)}
                >
                  <img
                    src={image.image}
                    alt={`${product.product_name}-${index + 1}`}
                    className="w-full h-full object-cover"
                  />
                </div>
              ))}
            </div>
          </div>

          {/* 右侧：联系方式和支付选项 */}
          <div className="relative min-h-[400px] lg:aspect-[4/3]">
            <div className="h-full flex flex-col">
              <div className="flex-1">
                <h1 className="text-3xl font-bold text-[#000000] mb-4">{product?.product_name}</h1>
                
                <div className="w-full h-[1px] bg-gray-200 mb-6"></div>
                
                <div className="space-y-6">
                  <div>
                    <div className="mb-6">
                      <label className="block text-xl text-[#000000] mb-4">您的电话：</label>
                      <p className="text-xl text-[#000000]">{profile?.user_phone_number}</p>
                    </div>

                    <div className="w-full h-[1px] bg-gray-200 mb-6"></div>
                    
                    <div>
                      <label className="block text-xl text-[#000000] mb-4">您的地址：</label>
                      <p className="text-xl text-[#000000]">{profile?.user_address}</p>
                    </div>
                  </div>
                </div>
              </div>

              {/* 支付宝按钮 */}
              <div className="mt-8 flex justify-end">
                <button
                  onClick={handlePayment}
                  className="w-40 border-2 border-[#000000] text-[#000000] rounded-full py-3 hover:bg-gray-100 transition-colors text-xl"
                  disabled={isProcessingPayment}
                >
                  {isProcessingPayment ? '处理中...' : '支付宝支付'}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* 支付确认模态框 */}
        <Dialog 
          open={showPaymentModal}
          as="div" 
          className="relative z-10" 
          onClose={() => {}}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-[420px] transform overflow-hidden rounded-2xl bg-white p-10 text-left align-middle shadow-xl transition-all min-h-[560px] relative">
                  {!isCheckingPayment ? (
                    <>
                      <div className="flex justify-between items-center mb-12">
                        <div className="w-8"></div>
                        <Dialog.Title
                          as="h3"
                          className="text-3xl font-medium text-center flex-grow"
                        >
                          确认支付
                        </Dialog.Title>
                        <button
                          onClick={handleClosePaymentModal}
                          className="text-black"
                        >
                          关闭
                        </button>
                      </div>

                      <div className="flex flex-col items-center justify-center space-y-8">
                        {/* 用户头像 */}
                        <div className="w-24 h-24 rounded-full overflow-hidden mb-4">
                          {profile?.user_avatar ? (
                            <img
                              src={profile.user_avatar}
                              alt="用户头像"
                              className="w-full h-full object-cover"
                            />
                          ) : (
                            <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                              <span className="text-3xl text-gray-400">头像</span>
                            </div>
                          )}
                        </div>

                        {/* 用户名称问候语 */}
                        <div className="text-xl font-medium text-center mb-8">
                          嗨，{profile?.user_name}！
                        </div>

                        <div className="text-center mb-12">
                          <div className="text-xl font-medium">
                            确认支付金额：¥{product?.product_price}
                          </div>
                        </div>

                        {/* 调整确认支付按钮的位置和样式 */}
                        <div className="absolute bottom-8 right-10">
                          <button
                            onClick={handleConfirmPayment}
                            className="w-32 bg-[#0000ff] text-white rounded-full py-3 text-xl"
                            disabled={isProcessingPayment}
                          >
                            {isProcessingPayment ? '处理中...' : '确认支付'}
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="flex flex-col items-center justify-center space-y-8">
                      <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-black"></div>
                      <p className="text-base text-center">
                        请在新打开的窗口中完成支付
                        <br />
                        支付完成后将自动跳转
                      </p>
                      <button
                        onClick={handleManualCheck}
                        className="w-32 bg-[#0000ff] text-white rounded-full py-3 hover:bg-[#0000cc]"
                      >
                        我已完成支付
                      </button>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>

        {/* 灯箱 */}
        {isLightboxOpen && (
          <div 
            className="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center"
            onClick={handleCloseLightbox}
          >
            {/* 关闭按钮 - 固定在右上角 */}
            <button
              className="fixed top-4 right-4 lg:top-8 lg:right-8 text-white text-4xl lg:text-6xl z-50 w-12 h-12 flex items-center justify-center"
              onClick={() => setIsLightboxOpen(false)}
            >
              ×
            </button>

            {/* 图片和箭头的容器 */}
            <div className="relative w-full h-[calc(100vh-120px)] lg:h-auto lg:max-w-5xl mx-auto px-4 lg:px-0">
              <div className="relative flex items-center justify-center h-full">
                {/* 桌面端的左右箭头和图片 */}
                {product.images && product.images.length > 1 && (
                  <button
                    className="absolute -left-32 text-white text-8xl w-16 h-32 hidden lg:flex items-center justify-center"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleImageChange('prev');
                    }}
                  >
                    ‹
                  </button>
                )}

                <div className="h-full lg:h-auto lg:max-h-[70vh] w-full flex flex-col items-center">
                  <img
                    src={product.images[currentImageIndex].image}
                    alt={product.product_name}
                    className="w-full h-full lg:h-auto lg:max-h-[70vh] object-contain mx-auto"
                    onClick={(e) => e.stopPropagation()}
                  />

                  {/* 移动端的左右箭头 */}
                  {product.images && product.images.length > 1 && (
                    <div className="fixed bottom-4 left-0 right-0 flex justify-center items-center space-x-8 mt-4 lg:hidden">
                      <button
                        className="text-white text-4xl w-12 h-12 flex items-center justify-center"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleImageChange('prev');
                        }}
                      >
                        ‹
                      </button>
                      <button
                        className="text-white text-4xl w-12 h-12 flex items-center justify-center"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleImageChange('next');
                        }}
                      >
                        ›
                      </button>
                    </div>
                  )}
                </div>

                {product.images && product.images.length > 1 && (
                  <button
                    className="absolute -right-32 text-white text-8xl w-16 h-32 hidden lg:flex items-center justify-center"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleImageChange('next');
                    }}
                  >
                    ›
                  </button>
                )}
              </div>
            </div>
          </div>
        )}

        {isCheckingPayment && (
          <Dialog 
            open={isCheckingPayment}
            onClose={() => {}} // 防止用户关闭对话框
            className="relative z-50"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Dialog.Panel className="w-full max-w-[420px] transform overflow-hidden rounded-2xl bg-white p-10 text-left align-middle shadow-xl transition-all min-h-[560px] relative">
                  <div className="flex justify-between items-center mb-12">
                    <div className="w-8"></div>
                    <Dialog.Title
                      as="h3"
                      className="text-3xl font-medium text-center flex-grow"
                    >
                      等待支付完成
                    </Dialog.Title>
                    <button
                      onClick={handleCancelPayment}
                      disabled={isProcessingPayment}
                      className={`text-black ${isProcessingPayment ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                      {isProcessingPayment ? '处理中...' : '关闭'}
                    </button>
                  </div>

                  <div className="flex flex-col items-center justify-center space-y-8">
                    <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-black"></div>
                    <p className="text-base text-center">
                      请在新打开的窗口中完成支付
                      <br />
                      支付完成后将自动跳转
                    </p>
                    <button
                      onClick={handleManualCheck}
                      className="w-full max-w-xs bg-black text-white rounded-full py-3 text-xl"
                    >
                      我已完成支付
                    </button>
                  </div>
                </Dialog.Panel>
              </div>
            </div>
          </Dialog>
        )}
      </div>
    </>
  );
}

export default Payment;