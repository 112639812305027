import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Contact</title>
        <meta name="description" content="联系我们" />
      </Helmet>

      <div className="max-w-screen-xl mx-auto px-6 py-12">
        <h1 className="text-3xl font-bold mb-8">Contact</h1>
        
        <div className="space-y-8 max-w-2xl">
          {/* 中文内容 */}
          <div className="text-base leading-relaxed space-y-4 text-[#000000]">
            <p>
              我们团队保持精简，是一群彼此鼓舞，相信山谷有蝴蝶的人。
            </p>
            <p>
              从产品设计、逻辑调整、代码完成、用户体验和版本更新，持续运营。
            </p>
            <p>
              如果想现在就联系我们，可以先联系我们的一位负责人：
              <a href="mailto:charles@emthes.com" className="hover:opacity-75">Charles</a>
              ，他一直都在。
            </p>
          </div>

          {/* 英文内容 */}
          <div className="text-base leading-relaxed space-y-4 text-[#000000]">
            <p>
              Our team is lean—a group that inspires one another, believing that even in the valleys, there are butterflies.
            </p>
            <p>
              Our operations continue seamlessly, spanning product design, logical adjustments, code development, user experience, and version optimization.
            </p>
            <p>
              If you wish to contact us immediately, please feel free to reach out to one of our leaders, Charles, who is always available.
            </p>
            <p>
              Welcome to: <a href="mailto:charles@emthes.com" className="hover:opacity-75">charles@emthes.com</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;