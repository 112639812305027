import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet-async';

function Account() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  
  useEffect(() => {
    const userCookie = Cookies.get('user');
    if (!userCookie) {
      navigate('/');
    } else {
      try {
        const userData = JSON.parse(userCookie);
        setUser(userData);
      } catch (error) {
        console.error('解析用户数据失败:', error);
        navigate('/');
      }
    }
  }, [navigate]);

  return (
    <>
      <Helmet>
        <title>账号信息</title>
        <meta name="description" content="查看您的账户信息和账单记录" />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 py-8 sm:py-12">
        <div className="text-[30px] font-bold mb-8">
          嗨，{user?.user_name}！您的账户：
        </div>
        
        <div className="flex flex-col space-y-12 mt-16">
          <div>
            <Link 
              to="/account/profiles" 
              className="text-[30px] font-bold inline-block"
            >
              查看账户信息
            </Link>
          </div>

          <div className="border-t border-gray-300 w-full"></div>

          <div>
            <Link 
              to="/account/bills" 
              className="text-[30px] font-bold inline-block"
            >
              查看账单信息
            </Link>
          </div>

          <div className="border-t border-gray-300 w-full"></div>
        </div>
      </div>
    </>
  );
}

export default Account; 